<template>
  <div>
    <!-- Start Breadcrum dan title -->
    <div class="ContentHeader">
      <div class="HeaderAll">
        <slot name="titlebreadcrumb"></slot>
        <a-breadcrumb>
          <a-breadcrumb-item
            ><router-link :to="{ name: 'Home' }">Home</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item v-for="(item, i) in breadcrumb" :key="i">
            <router-link
              :to="{ name: item.nameroute }"
              v-if="i + 1 < breadcrumb.length"
              >{{ item.name }}</router-link
            >
            <span v-else>{{ item.name }}</span>
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
    </div>
    <!-- End Breadcrumb dan title -->
    <!-- slot menu -->
    <slot name="menuall"></slot>

    <div
      class="allcard"
      style="border-top-left-radius: 0px; height: calc(100vh - (282px + 3.7vh))"
    >
      <simplebar class="simplebarcustom" data-simplebar-auto-hide="false">
        <div style="display: flex; justify-content: flex-end">
          <div class="ContentHeaderSearch">
            <a-input-search
              placeholder="Search Here"
              style="width: 327px"
              @search="onSearch"
              allowClear
            />

            <!-- <a-button type="advancedsearch" @click="advanced"
            >Advanced Search <a-icon type="filter" />
          </a-button>
          <div class="advancedsearch-dropdown" v-if="advancedsearch">
            <div class="_dropdownwraper">asdsadas</div>
          </div> -->
          </div>
        </div>
        <!-- Start Table Upload Failed -->
        <a-table
          :rowKey="(record) => record.id"
          :columns="columns"
          :dataSource="data"
          :pagination="false"
        >
          <template slot="nama_group" slot-scope="text, record">
            <router-link
              :to="{
                name: 'ListDaftarPT',
                params: { id: record.id_job_order },
              }"
            >
              {{ text }} <a-icon type="right" />
            </router-link>
          </template>
          <template slot="periode_polis" slot-scope="text, record">
            <div class="iconcalendar">
              <img :src="require('@/assets/img/icon/calendar.svg')" />
              {{ record.periode_awal_format }}
            </div>
          </template>
          <template slot="jenis_asuransi" slot-scope="text">
            <div class="iconhealth" v-if="text == 'HEALTH'">
              <img :src="require('@/assets/img/icon/health.svg')" />{{ text }}
            </div>

            <div class="iconhealth iconlife" v-if="text == 'LIFE'">
              <img :src="require('@/assets/img/icon/life.svg')" />{{ text }}
            </div>

            <div class="iconhealth iconih" v-if="text == 'IH'">
              <img :src="require('@/assets/img/icon/ih.svg')" />{{ text }}
            </div>
          </template>
          <template slot="tgl_upload" slot-scope="text, record">
            <div class="iconcalendar">
              <img :src="require('@/assets/img/icon/calendar.svg')" />
              {{ record.tanggal_upload }}
            </div>
          </template>

          <template slot="action" slot-scope="text, record">
            <span v-if="record.action == null">
              <a-button
                type="download"
                @click="download(record.id, record.file_mart)"
                >Download <img :src="require('@/assets/img/icon/download.svg')"
              /></a-button>
            </span>

            <span v-else>
              <a-button
                type="upload"
                @click="UploadFile(record.id_job_order, record.id)"
                >Upload <img :src="require('@/assets/img/icon/download.svg')"
              /></a-button>
            </span>
          </template>
          <template slot="footer">
            {{ null }}
          </template>
        </a-table>
        <ModalImportDataPeserta
          ref="importData"
          @fetch="
            fetch({
              page: page,
              pageSizes: pageSize,
            })
          "
        />
      </simplebar>
    </div>
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import $axios from "@/utils/axios.js";
import ModalImportDataPeserta from "@/components/Modal/reImportDataPeserta.vue";
const columns = [
  {
    title: "Nama Group",
    dataIndex: "nama_group",
    scopedSlots: { customRender: "nama_group" },
    sorter: true,
  },
  {
    title: "Periode Polis",
    dataIndex: "periode_polis",
    scopedSlots: { customRender: "periode_polis" },
    sorter: true,
  },
  {
    title: "Jenis Asuransi",
    dataIndex: "jenis_asuransi",
    scopedSlots: { customRender: "jenis_asuransi" },
    align: "center",
    sorter: true,
  },
  {
    title: "Tgl Upload",
    dataIndex: "tgl_upload",
    sorter: true,

    scopedSlots: { customRender: "tgl_upload" },
  },

  {
    title: "Action",
    dataIndex: "download",
    scopedSlots: { customRender: "action" },
    align: "center",
  },
];
export default {
  components: {
    ModalImportDataPeserta,
    simplebar,
  },
  data() {
    return {
      data: [],
      columns,
      pageSizeOption: ["15", "30", "60", "1000000"],
      pagination: {},
      total: 20,
      page: 1,
      pageSize: 15,
      loading: false,
      advancedsearch: false,
      url: $axios.defaults.baseURL.replace("api", ""),
      breadcrumb: [
        {
          name: "Upload Failed",
          nameroute: "",
          param: {},
        },
      ],
    };
  },

  mounted() {
    this.fetch({
      page: this.page,
      pageSizes: this.pageSize,
    });
    document.body.classList.add("ModalAlert");
  },
  methods: {
    onSearch(value) {
      (this.search = value),
        this.fetch({
          page: this.page,
          pageSizes: this.pageSize,
          search: value,
        });
    },
    advanced() {
      this.advancedsearch != this.advancedsearch;
    },
    UploadFile(id, id_antrian) {
      this.$refs.importData.Show(id, id_antrian);
    },
    download(id, e) {
      window.open(this.url + "/" + e);
      this.$store
        .dispatch("PostMarkDownload", {
          id: id,
        })
        .then((response) => {
          this.fetch({
            page: this.page,
            pageSizes: this.pageSize,
          });
        });
    },
    fetch(params = {}) {
      this.loading = true;
      this.$store
        .dispatch("getFailedUpload", {
          ...params,
        })
        .then((response) => {
          this.loading = false;
          const pagination = { ...this.pagination };
          pagination.total = response.data.total;
          pagination.pageSize = response.data.pageSize;
          pagination.page = response.data.page;

          this.total = pagination.total;
          this.data = response.data.data;
          this.pagination = pagination;
          this.model = [];
        });
    },
  },
};
</script>
