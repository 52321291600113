var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"ContentHeader"},[_c('div',{staticClass:"HeaderAll"},[_vm._t("titlebreadcrumb"),_c('a-breadcrumb',[_c('a-breadcrumb-item',[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_vm._v("Home")])],1),_vm._l((_vm.breadcrumb),function(item,i){return _c('a-breadcrumb-item',{key:i},[(i + 1 < _vm.breadcrumb.length)?_c('router-link',{attrs:{"to":{ name: item.nameroute }}},[_vm._v(_vm._s(item.name))]):_c('span',[_vm._v(_vm._s(item.name))])],1)})],2)],2)]),_vm._t("menuall"),_c('div',{staticClass:"allcard",staticStyle:{"border-top-left-radius":"0px","height":"calc(100vh - (282px + 3.7vh))"}},[_c('simplebar',{staticClass:"simplebarcustom",attrs:{"data-simplebar-auto-hide":"false"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('div',{staticClass:"ContentHeaderSearch"},[_c('a-input-search',{staticStyle:{"width":"327px"},attrs:{"placeholder":"Search Here","allowClear":""},on:{"search":_vm.onSearch}})],1)]),_c('a-table',{attrs:{"rowKey":(record) => record.id,"columns":_vm.columns,"dataSource":_vm.data,"pagination":false},scopedSlots:_vm._u([{key:"nama_group",fn:function(text, record){return [_c('router-link',{attrs:{"to":{
              name: 'ListDaftarPT',
              params: { id: record.id_job_order },
            }}},[_vm._v(" "+_vm._s(text)+" "),_c('a-icon',{attrs:{"type":"right"}})],1)]}},{key:"periode_polis",fn:function(text, record){return [_c('div',{staticClass:"iconcalendar"},[_c('img',{attrs:{"src":require('@/assets/img/icon/calendar.svg')}}),_vm._v(" "+_vm._s(record.periode_awal_format)+" ")])]}},{key:"jenis_asuransi",fn:function(text){return [(text == 'HEALTH')?_c('div',{staticClass:"iconhealth"},[_c('img',{attrs:{"src":require('@/assets/img/icon/health.svg')}}),_vm._v(_vm._s(text)+" ")]):_vm._e(),(text == 'LIFE')?_c('div',{staticClass:"iconhealth iconlife"},[_c('img',{attrs:{"src":require('@/assets/img/icon/life.svg')}}),_vm._v(_vm._s(text)+" ")]):_vm._e(),(text == 'IH')?_c('div',{staticClass:"iconhealth iconih"},[_c('img',{attrs:{"src":require('@/assets/img/icon/ih.svg')}}),_vm._v(_vm._s(text)+" ")]):_vm._e()]}},{key:"tgl_upload",fn:function(text, record){return [_c('div',{staticClass:"iconcalendar"},[_c('img',{attrs:{"src":require('@/assets/img/icon/calendar.svg')}}),_vm._v(" "+_vm._s(record.tanggal_upload)+" ")])]}},{key:"action",fn:function(text, record){return [(record.action == null)?_c('span',[_c('a-button',{attrs:{"type":"download"},on:{"click":function($event){return _vm.download(record.id, record.file_mart)}}},[_vm._v("Download "),_c('img',{attrs:{"src":require('@/assets/img/icon/download.svg')}})])],1):_c('span',[_c('a-button',{attrs:{"type":"upload"},on:{"click":function($event){return _vm.UploadFile(record.id_job_order, record.id)}}},[_vm._v("Upload "),_c('img',{attrs:{"src":require('@/assets/img/icon/download.svg')}})])],1)]}}])},[_c('template',{slot:"footer"},[_vm._v(" "+_vm._s(null)+" ")])],2),_c('ModalImportDataPeserta',{ref:"importData",on:{"fetch":function($event){return _vm.fetch({
            page: _vm.page,
            pageSizes: _vm.pageSize,
          })}}})],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }